.mapboxgl-control-container {
  display: none;
}
.search {
  position: absolute;
  top: 89px;
  z-index: 1;
  width: 100%;
}

.search input {
  background: #fff;
  border: 1px solid #11c9e3;
  border-radius: 16px;
  width: 100%;
  height: 18px;
  padding: 21px 24px;
  padding-left: 40px;
  font-size: 18px;
}

.search mapbox-search-box > div {
  border-radius: 20px;
}

.button-link {
  cursor: pointer;
  z-index: 1;
  border: 3px solid #11c9e3;
  justify-content: space-evenly;
  width: 110px;
  height: 34px;
  display: flex;
  position: absolute;
  top: 133px;

  font-size: 16px;
  align-items: center;
  border: 3px solid #11c9e3;
  justify-content: space-evenly;
  align-items: center;
}

@media only screen and (width: 393px) and (height: 852px) and (-webkit-device-pixel-ratio: 3) {
  .mapboxgl-control-container {
    display: none;
  }
  .search {
    position: absolute;
    top: 89px;
    z-index: 1;
    width: 100%;
  }

  .search input {
    background: #fff;
    border: 1px solid #11c9e3;
    border-radius: 16px;
    width: 100%;
    height: 18px;
    padding: 21px 24px;
    padding-left: 40px;
    font-size: 18px;
  }

  .search mapbox-search-box > div {
    border-radius: 20px;
  }

  .button-link {
    cursor: pointer;
    z-index: 1;
    border: 3px solid #11c9e3;
    justify-content: space-evenly;
    width: 110px;
    height: 34px;
    display: flex;
    position: absolute;
    top: 133px;

    font-size: 16px;
    align-items: center;
    border: 3px solid #11c9e3;
    justify-content: space-evenly;
    align-items: center;
  }
}
