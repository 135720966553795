@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

body {
  margin: 0;
  /* font-family: 'Space Grotesk'; */
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;

  min-height: 100vh;
}

.react-draggable {
  overflow: auto;
}

@media screen and (max-width: 999px) {
  .react-draggable {
    position: unset !important;
    transform: none !important;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .react-draggable {
    position: unset !important;
    transform: none !important;
    overflow: inherit;
  }
}

.leaflet-image-layer img {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 540px) {
  .react-draggable {
    overflow: inherit;
  }
}
